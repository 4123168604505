import { Controller } from 'stimulus'
import GLightbox from 'glightbox'

export default class extends Controller {
    static targets = []

    connect() {
        this.initializeLightbox()
        this.bindBackButton()
    }

    bindBackButton() {
        const lightbox = this.lightbox

        window.addEventListener('hashchange', () => {
            if (location.hash === '') {
                this.lightbox.close()
            }
        })
    }

    initializeLightbox() {
        this.lightbox = GLightbox({ selector: 'data-lightbox' })
        this.lightbox.on('open', () => { location.hash = 'lightbox' })
    }
}
